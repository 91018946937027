import {HWMap} from "../../core/common/common.vo";

import axios from 'axios'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import APIs from "../apis/APIs";
import MemberUtils from "./member.utils";
import PointService from "../../core/point/point.service";
import WebUtils from "./web.utils";

let isSavingPoint = false;
let isTokenRefreshing = false;
let refreshSubscribers: ((accessToken: string) => void)[] = [];

const onTokenRefreshed = (accessToken: string) => {
    refreshSubscribers.map((callback) => callback(accessToken))
}

const addRefreshSubscriber = (callback: (accessToken: string) => void) => {
    refreshSubscribers.push(callback)
}

function getCurrentDateKey() {
    const now = new Date();
    return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}`;
}

class AxiosCaller {
    private static setToken(token: string) {
        localStorage.setItem("token", token);
    }

    private static getToken() {
        return localStorage.getItem("token");
    }

    static clearInterceptor() {
        axios.interceptors.request.clear();
        axios.interceptors.response.clear();
    }

    static setInterceptor(isProgress: boolean) {
        this.clearInterceptor();

        // intercept request
        axios.interceptors.request.use(async (config: any) => {
            if (isProgress) {
                NProgress.start();
            }

            const token = AxiosCaller.getToken();

            // JWT 토큰 첨부
            if (token) {
                config.headers = {
                    Authorization: "Bearer " + token,
                    ...config.headers,
                };

                const sessionVO = localStorage.getItem("sessionVO");

                if (sessionVO) {
                    const sessionIdKey = "sessionid";
                    const coEmailId = JSON.parse(sessionVO).coEmailId;

                    if (config.data instanceof FormData) {
                        config.data.delete(sessionIdKey);
                        config.data.append(sessionIdKey, coEmailId);
                    } else {
                        config.data = {
                            ...config.data,
                            [sessionIdKey]: coEmailId
                        };
                    }
                }
            }

            const url = config.url;
            const userId = config.data.sessionid

            if (
                userId !== undefined                                                        // userId 가 존재 할 때
                && !isSavingPoint                                                           // 검증 및 적립 중 중복 호출 방지 Flag
                && !url.includes(APIs.POINT + "/getValidateLoginPoint")                     // 로그인 포인트 검증 API
                && !url.includes(APIs.POINT + "/savePoint")                                 // 포인트 적립 API
                && !url.includes(APIs.SESSION + "/doLogin")                                 // 로그인 API
                && !url.includes(APIs.SESSION + "/doRecordLogin")                           // 로그인 기록 API
                && !url.includes(APIs.CO_ACCOUNT + "/records/getCountRecord4Date")          // 로그인 기록 확인 API
            ) {
                const currentDateKey = getCurrentDateKey();
                const lastSavePointDate = localStorage.getItem("last-save-point");

                if (lastSavePointDate !== currentDateKey) {
                    isSavingPoint = true;

                    localStorage.setItem("last-save-point", currentDateKey);

                    try {
                        const validResult = await (this.get(APIs.POINT + "/getValidateLoginPoint", {userId}) as Promise<HWMap>);
                        const valid = validResult.valid;

                        if (!valid) {
                            const saveResult = await PointService.savePoint("LOGIN");

                            if (saveResult && saveResult.status === "SUCCESS") {

                                const date = new Date();

                                const countRecord = await (this.get(
                                    APIs.CO_ACCOUNT + "/records/getCountRecord4Date",
                                    {
                                        userId,
                                        isDuplicate: false,
                                        startDate: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                                        endDate: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate() + 1)
                                    }
                                ) as Promise<HWMap>);

                                if (countRecord && countRecord.status === "ok" && countRecord.count === 0) {
                                    this.post(APIs.SESSION + "/doRecordLogin", {sessionid: userId});
                                }
                            }
                        }
                    } catch (e) {
                    } finally {
                        isSavingPoint = false;
                    }
                }
            }

            return config;
        }, (error: any) => {
            if (isProgress) {
                NProgress.done();
            }

            return Promise.reject(error);
        });

        // intercept response
        axios.interceptors.response.use((response: any) => {
            if (isProgress) {
                NProgress.done();
            }

            return response;
        }, async (error: any) => {
            const {config, response} = error;
            let url = "";

            if (!response) {
                return Promise.reject(error);
            }

            if (response.config && response.config.url) {
                url = response.config.url;
            }

            if (!url.includes("/refresh/token") && !url.includes(APIs.POINT + "/savePoint") && response.status === 401) {
                // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
                const retryOriginalRequest = new Promise((resolve) => {
                    addRefreshSubscriber((accessToken) => {
                        config.headers.Authorization = "Bearer " + accessToken;
                        resolve(axios(config));
                    });
                });

                if (!isTokenRefreshing) {
                    isTokenRefreshing = true;

                    try {
                        const authAxios = axios.create();
                        let result = await authAxios.post(APIs.SESSION + "/refresh/token", {}, {withCredentials: true});

                        AxiosCaller.setToken(result.data.token);
                        isTokenRefreshing = false;
                        onTokenRefreshed(result.data.token);
                        refreshSubscribers = [];
                    } catch (e: any) {
                        let notReloaded = !sessionStorage.getItem("hasReloaded");

                        MemberUtils.clearLocalStorageExcept(["isRemember", "rememberUserId", "savedJobSkillDate"]);
                        sessionStorage.clear();

                        if (notReloaded) {
                            sessionStorage.setItem("hasReloaded", "true");
                            window.location.reload();
                        } else {
                            window.location.href = "/login";
                        }
                    } finally {
                        isTokenRefreshing = false;
                        refreshSubscribers = [];
                    }
                }

                return retryOriginalRequest
            }

            if (isProgress) {
                NProgress.done();
            }

            return Promise.reject(error);
        });
    }


    static async ajax<HWMap>(url: string, method: string, data: HWMap, headers = {}, params = {}, isProgress: boolean) {
        try {
            this.setInterceptor(isProgress)

            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                params,
                withCredentials: true,
            })

            const result: HWMap = {
                ...response.data,
                statusCode: response.status,
                statusText: response.statusText
            }

            return result;

        } catch (error) {
            console.error('AJAX M_Error:', error);
            //closeLoading();
            throw error;
        }
    }


    static get<T = any>(url: string, params?: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.ajax(url, 'GET', {}, headers, params, isProgress);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static post<T = any>(url: string, data: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.ajax(url, 'POST', data, headers, undefined, isProgress);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static put<T = any>(url: string, data: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.ajax(url, 'PUT', data, headers, undefined, isProgress);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static patch<T = any>(url: string, data: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.ajax(url, 'PATCH', data, headers, undefined, isProgress);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static delete<T = any>(url: string, params: HWMap = {}, headers = {}, isProgress: boolean = true): HWMap {
        try {

            return this.ajax(url, 'DELETE', {}, headers, params, isProgress);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }


    static getHide<T = any>(url: string, params?: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.get(url, params, headers, false);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static postHide<T = any>(url: string, data: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.post(url, data, headers, false);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static putHide<T = any>(url: string, data: HWMap, headers = {}): HWMap {
        try {
            return this.put(url, data, headers, false);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static patchHide<T = any>(url: string, data: HWMap, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.patch(url, data, headers, false);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

    static deleteHide<T = any>(url: string, params: HWMap = {}, headers = {}, isProgress: boolean = true): HWMap {
        try {
            return this.delete(url, params, headers, false);
        } catch (error) {
            console.error('AJAX M_Error:', error);
            throw error;
        }
    }

}

export default AxiosCaller;