import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import CodeUtils from "../../../../common/utils/code.utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import MemberUtils from "../../../../common/utils/member.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";

/**
 * 게시판 아이템
 * @param board
 * @param isNeedLogin : 로그인이 필수인 경우에 로그인 confirm 창을 표출합니다.
 * @constructor
 */
const BasicItem: React.FC<{ board: BoardVO, isNeedLogin: boolean, boardInfo?: BoardInfoVO, index? :number}> = ({board, isNeedLogin, boardInfo, index}) => {

    let {boardKey} = useParams();
    const location = useLocation()
    const [coName, setCoName] = React.useState<string>("");
    const navigate = useNavigate();



    useEffect(() => {
        
        if ( board.writerInfo ) {
            CodeUtils.getCoCodeNames(board.writerInfo.coCode)
                .then(names => {
                    setCoName(names);
                })
                .catch(error => {
                    console.error('M_Error fetching code names:', error);
                    setCoName('Failed to load names');
                });
        }
    }, [board.coCode]);

    // TODO: 테스트용
    useEffect(() => {
        console.log("isNeedLogin : ", isNeedLogin)
    }, [isNeedLogin]);

    const checkLogin = () => {
        if (MemberUtils.getSessionVO() == null) {
            if (window.confirm('로그인이 필요합니다. 로그인 페이지로 넘어가시겠습니까?')) {
                navigate('/login');
            }
        } else {
            goView()
        }
    }

    const goView = () => {
        // StringUtils.filterEmpty 함수를 사용하여 타이틀이 비어있지 않은지 확인
        const title = StringUtils.titleUrlFilter(board.title) || 'default-title';

        if(board.boardKey == "use_story") {
            // story/:boardKey/:bidx/:boardTitle
            navigate(`/board/story/${board.boardKey}/${board.idx}/${encodeURIComponent(title)}`, {state:{from:location.pathname}});
        } else {
            navigate(`/board/${board.boardKey}/${board.idx}/${encodeURIComponent(title)}`, {state:{from:location.pathname}});
        }

    };

    return (<>{
        boardKey != "use_story"
            ? <tr style={{cursor: "pointer"}} onClick={isNeedLogin ? checkLogin : goView}>
                <td>{coName}</td>
                {
                    (!boardInfo || boardInfo.isTarget) &&
                    <td>{board.jobName}</td>
                }
                <td className="tit">
                    <div>
                        <strong>{board.title}</strong>
                        {
                            board.reviewCount > 0 && (<span className="comment" style={{marginLeft: '4px'}}>({board.reviewCount})</span>)
                        }
                        {board.isFile && <span className="attach"></span>}
                    </div>
                </td>
                <td>{board.writer}</td>
                <td className="date">{DateUtils.formatDate(board.createDate)}</td>
                <td className="hits">{board.views}</td>
             </tr>
            : <tr style={{cursor: "pointer"}} onClick={isNeedLogin ? checkLogin : goView}>
                <td>
                    {board.isNotice ? <span className="badge type1 red">BEST</span> : index ? index : board.idx}
                </td>
                <td>{board.cateName}</td>
                <td className="tit">
                    <div>
                        <strong>{board.title}</strong>
                    </div>
                    <p className="desc">{board.contentText}</p>
                </td>
                <td>{board.writer}</td>
                <td className="date">{DateUtils.formatDate(board.createDate)}</td>
             </tr>
    }</>)
}


export default BasicItem;