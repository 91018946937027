import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';

import DateUtils from "../../../../common/utils/date.utils";
import {M_EduContentIndex, M_EduTitleDom} from "../M_EduMainDetail";
import {
	AnsTstVO,
	EduAnswerVO,
	EduContentVO,
	EduMainVO,
	QstTstOptionVO,
	QstTstVO,
	TstAnsVO
} from "../../../../core/education/education.vo";
import WebUtils from "../../../../common/utils/web.utils";
import {HWMap} from "../../../../core/common/common.vo";
import StringUtils from "../../../../common/utils/string.utils";
import EducationService from "../../../../core/education/education.service";
import educationService from "../../../../core/education/education.service";
import ArrayUtils from "../../../../common/utils/array.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import CommonJS from "../../../../common/common";
import ToastUtils from "../../../../common/utils/toast.utils";


const M_QstTst:React.FC<{paramMap:HWMap}> = ({paramMap}) => {

	const navigate = useNavigate()
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO|null>(null)
	const [contentList, setContentList] = useState<EduContentVO[]|null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO|null>(null)
	const [qstTstList, setQstTstList] = useState<QstTstVO[]|null>(null)
	const [qstTstOption, setQstTstOption] = useState<QstTstOptionVO|null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO|null>(null)
	const [ansTstList, setAnsTstList] = useState<AnsTstVO[]|null>(null)
	const $ansTstList = useRef<AnsTstVO[]|null>(null)
	
	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO);
			
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] );
			setAnsTstList( paramMap.answerList[paramMap.requestStep]?.ansTstList );
			
			(async () => {
				const resultDetail = await educationService.getContentDetail({
					ecIdx: paramMap.eduContentVO.ecIdx
				})
				
				// 답안 재배열일 경우 셔플 실행
				resultDetail.qstTstList?.map((item:QstTstVO) => {
					if ( item.isShuffle ) {
						item.answerList = ArrayUtils.shuffleArray(item.answerList)
					}
				});
				
				setQstTstList(resultDetail.qstTstList)
				setQstTstOption(resultDetail.qstTstOption)
				
				WebUtils.goTop()
			})()
		}
	}, [paramMap]);
	
	
	
	/**
	 * EduAnswerVO 데이터 확인 시 자동저장 실행
	 */
	const $intervalSave = useRef<NodeJS.Timeout | null>(null)

    const clearIntervalSave = () => {
        if ( $intervalSave.current ) {
            clearInterval($intervalSave.current);
            $intervalSave.current = null;
        }
    }

	useEffect(() => {

		if ( eduMainVO && eduAnswerVO && !eduAnswerVO.isComplete && !eduMainVO.isEnd ) {
			const interval = 1000 * 60;
			$intervalSave.current = setInterval(() => autoIntervalSave(), interval)
		} else {
            clearIntervalSave();
        }
		
		return clearIntervalSave;
		
	}, [eduAnswerVO])
	
	
	
	
	
	
	/**
	 * 정해진 시간뒤 자동 저장
	 * @param _seconds
	 */
	const autoIntervalSave = async () => {

		if (eduAnswerVO && eduMainVO) {
			if (!eduAnswerVO.isComplete && !eduMainVO.isEnd ) {
				await btnClickSave(false)
			}
		}
	}
	
	
	
	
	
	const inputAnswers = async ( qt_index:number, ta_idx:number|null, shortTxt:string|null ) => {
		const tmpList = [...ansTstList!]
		if ( qstTstList && qstTstList[qt_index].tstType === "single" ) {
			tmpList[qt_index] = {
				...tmpList[qt_index],
				selTaIdxs : ta_idx!.toString()
			};
		} else if ( qstTstList && qstTstList[qt_index].tstType === "multi" ) {
			const checkedValues = Array.from(document.querySelectorAll<HTMLInputElement>(`input[name='qstTst-${qt_index}']:checked`)).map(input => input.value);
			tmpList[qt_index] = {
				...tmpList[qt_index],
				selTaIdxs : checkedValues.join(",")
			};
		} else if ( qstTstList && qstTstList[qt_index].tstType === "short" ) {
			tmpList[qt_index] = {
				...tmpList[qt_index],
				selTaIdxs : "",
				shortTxt : shortTxt
			};
		}
		await setAnsTstList( tmpList )
	}
	useEffect(() => {
		$ansTstList.current = ansTstList
	}, [ansTstList])
	
	
	
	/**
	 * 답변 데이터가 모두 있는지 확인
	 * 제출 가능 결과 반환
	 */
	const isEnd = async () => {
		
		let result: boolean = true
		if (eduContentVO?.ecIdx && ansTstList) {
			ansTstList.map((item, index) => {
				if (!item.selTaIdxs && !item.shortTxt && !item.selFidxs) {
					result = false
					return result;
				}
			})
		}
		return result;
	}
	
	
	
	const btnClickSave = async (_isSubmission:boolean) => {
		if ( eduContentVO?.ecIdx && ansTstList ) {
			
			if ( _isSubmission && !await isEnd() ) {
				SwalUtils.text({
					title : `코스학습`,
					message : `누락된 문제항목이 있습니다.`,
					isCancel : true,
					confirmOptions : {
						text : "확인"
					}
				})
				//alert("답변누락");
			} else {
				const response = await saveAnswer(_isSubmission)
				if ( response.status === `SUCCESS` ) {
					// _isSubmission && alert("제출 완료");
					// !_isSubmission && alert("임시저장 완료");
					_isSubmission && paramMap._retrievePage()
					!_isSubmission && ToastUtils.show(`입력하신 내용이 임시저장되었습니다.`)
				} else {
					
					if ( response.msg === "FAIL_ALREADY_RESULT" ) {
						paramMap._retrievePage(false);
						ToastUtils.show("이미 제출된 테스트입니다.");
						clearIntervalSave()
						return;
					}
					if ( response.msg === "FAIL_ALREADY_COMPLETE" ) {
						paramMap._retrievePage(false);
						ToastUtils.show("이미 완료된 테스트입니다.");
						clearIntervalSave()
						return;
					}
				}
			}
		}
	}
	
	
	/**
	 * 실제 답변 저장
	 * @param _isSubmission
	 */
	const saveAnswer = async (_isSubmission:boolean | null) => {
		EducationService.setAnsRecord(eduContentVO!.ecIdx, `REQUEST`, "tst")
		return await EducationService.setAnsTstList(eduContentVO!.ecIdx, $ansTstList.current!, _isSubmission)
	}
	
	


	return (
		<>
			<section className="detail-section type-test">
				{/*title*/}
				<div className="detail-title">
					<div className="inner">
						<M_EduTitleDom paramMap={{
							mainTitle: eduMainVO?.eduTitle,
							contentTitle: qstTstOption?.testTitle,
							startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
							endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
							introduce : eduMainVO?.introduce
						}} />
						<div className="test-content">
							<div className="text-area">
								<strong className="tit">테스트 안내</strong>
								<div className="text" dangerouslySetInnerHTML={{__html:StringUtils.nl2br(qstTstOption?.testNotice) || ""}}></div>
							</div>
						</div>
						<h2 className="tit">{qstTstOption?.testTitle}</h2>
						
						<M_EduContentIndex paramMap={paramMap} ></M_EduContentIndex>
						
					</div>
				</div>
				<div className="detail-content">
					<div className="inner">
						<div className="content-title">
							<h3 className="tit">테스트</h3>
						</div>
						<div className="form-list">
							
							{
								qstTstList?.map( (qstTst, qt_index) => (
									<div className="form-item" key={qt_index}>
										
										{/* 객관식 싱글 */}
										{qstTst.tstType === `single` && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
													<p className="desc">{qstTst.tstDesc}</p>
												</div>
												<div className="rdo-bind">
													{ qstTst.answerList.map((tstAns:TstAnsVO, ta_index:number) => (

														<div className={ !!(ansTstList && ansTstList[qt_index]?.selTaIdxs == tstAns.taIdx.toString()) ? "radio active" : "radio" } key={ta_index}>
															<input type="radio" name={`qstTst-${qt_index}`} id={`tstAns-${tstAns.taIdx}`} value={tstAns.taIdx} className="inp-radio"
															       checked={ !!(ansTstList && ansTstList[qt_index]?.selTaIdxs == tstAns.taIdx.toString())}
															       onChange={() => inputAnswers(qt_index, tstAns.taIdx, null)}
															       readOnly={eduMainVO?.isEnd}
															/>
															<label htmlFor={`tstAns-${tstAns.taIdx}`} className="lab-radio">
																{tstAns.answer} {/*{tstAns.taIdx}*/}
																{ tstAns.answerImgSrc &&  <img src={tstAns.answerImgSrc} alt={tstAns.answer} />}
																{ tstAns.answerAudioSrc && <audio src={tstAns.tstAudioSrc} controls={true}></audio>}
															</label>
														</div>
													
													))}
												</div>
											</>
										)}
										
										
										{/* 객관식 멀티 */}
										{qstTst.tstType === `multi` && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
													<p className="desc">{qstTst.tstDesc}</p>
												</div>
												<div className="rdo-bind">
													{ qstTst.answerList.map((tstAns:TstAnsVO, ta_index:number) => (
														
														<div className={ !!(ansTstList && ansTstList[qt_index]?.selTaIdxs?.indexOf(tstAns.taIdx.toString()) > -1) ? "radio active" : "radio" } key={ta_index}>
															<input type="checkbox" name={`qstTst-${qt_index}`} id={`tstAns-${tstAns.taIdx}`} value={tstAns.taIdx} className="inp-radio"
															       checked={ !!(ansTstList && ansTstList[qt_index]?.selTaIdxs?.indexOf(tstAns.taIdx.toString()) > -1)}
															       onChange={() => inputAnswers(qt_index, tstAns.taIdx, null)}
															       readOnly={eduMainVO?.isEnd}
															/>
															<label htmlFor={`tstAns-${tstAns.taIdx}`} className="lab-radio">
																{tstAns.answer} {/*{tstAns.taIdx}*/}
																{ tstAns.answerImgSrc &&  <img src={tstAns.answerImgSrc} alt={tstAns.answer} />}
																{ tstAns.answerAudioSrc && <audio src={tstAns.tstAudioSrc} controls={true}></audio>}
															</label>
														</div>
													
													))}
												</div>
											</>
										)}
										
										
										{/* 주관식 */}
										{qstTst.tstType === `short` && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
												</div>
												<div className="inp-bind">
													<textarea name={`qstTst-${qt_index}`} id={`qstTst-${qt_index}`} cols={30} rows={3}
													          className="inp-text" placeholder="답변을 이곳에 작성해주세요"
													          onChange={(e) => inputAnswers(qt_index, null, e.target.value)}
													          readOnly={eduMainVO?.isEnd}
													>{ansTstList && ansTstList[qt_index]?.shortTxt}</textarea>
												</div>
											</>
										)}
									
									
									</div>
								))
							}
							
							
						</div>
					</div>
				</div>
			</section>
			{/*Floating Area*/}
			<div className="floating-area">
				{/*Floating Button*/}
				<div className="floating-button">
					<div className="inner">
						<div className="button-area">
							{
								paramMap.isPossible &&
								<>
									<button type="button" className="btn gray lg" onClick={() => btnClickSave(false)}>임시저장</button>
									<button type="button" className="btn red lg" onClick={() => btnClickSave(true)}>제출하기</button>
								</>
							}
							{/*<button className="btn lg gray">임시저장</button>
							<button className="btn lg">제출하기</button>
							<button class="btn lg full">제출하기</button>*/}
						</div>
					</div>
				</div>
			</div>
		</>

	)
}
export default M_QstTst