import React, {useEffect, useRef} from "react";
import {BoardFileVO, BoardVO} from "../../../core/board/board.vo";
import WebUtils from "../../../common/utils/web.utils";
import FileUtils from "../../../common/utils/file.utils";
import M_PdfViewer from "../common/M_PdfViewer";

import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';
import {Navigation, Pagination} from "swiper/modules";
import CommonMobileJS from "../../../common/common_m";


const M_BoardCard: React.FC<{fileList: BoardFileVO[], boardVO: BoardVO}> = ({fileList, boardVO}) => {

    const swiperRef = useRef<SwiperType | null>(null);
    const swiperDetailRef = useRef<SwiperType | null>(null);
    const [cardFileList, setCardFileList] = React.useState<BoardFileVO[]>([]);
    const [ebook, setEbook] = React.useState<BoardFileVO>();

    useEffect(() => {
        CommonMobileJS.mediaModal();

    }, [cardFileList]);

    useEffect(() => {

        const resultList: BoardFileVO[] = [];
    
        if ( fileList.length > 0 ) {
            fileList.forEach((item: BoardFileVO) => {
                if (item.fileMode === "contents") {
                    resultList.push(item);
                }
            });
    
            const sortedFileList = resultList.sort((a: BoardFileVO, b: BoardFileVO) => a.ordering - b.ordering);
    
            setCardFileList(sortedFileList);
            console.log("⭐변경 fileList", sortedFileList);
        }

    }, [fileList]);




    useEffect(() => {

        console.log("⭐boardVO", boardVO);
        if ( boardVO.boardType === 'card' ) {

            if ( cardFileList && cardFileList.length > 0 ) {
                initSwiper();

                const preventDefaultBehavior = (event: Event) => {
                    event.preventDefault();
                };

                const images = document.querySelectorAll('.img-cardnews');

                images.forEach(image => {
                    image.addEventListener('contextmenu', preventDefaultBehavior);
                    image.addEventListener('mousedown', preventDefaultBehavior);
                    image.addEventListener('dragstart', preventDefaultBehavior);
                });

                return () => {
                    images.forEach(image => {
                        image.removeEventListener('contextmenu', preventDefaultBehavior);
                        image.removeEventListener('mousedown', preventDefaultBehavior);
                        image.removeEventListener('dragstart', preventDefaultBehavior);
                    });
                };
            }

        }


        if ( boardVO.boardType === 'pdf' ) {

            let ebook: BoardFileVO | undefined = cardFileList.find(file => file.fileext?.toLowerCase() === "pdf");

            if (ebook) {
                setEbook(ebook)
            }
        }

        WebUtils.goTop()

    }, [boardVO.boardType, cardFileList])


    const handleSlideClick = (index: number) => {
        console.log(`Slide ${index} clicked`);
        // 클릭한 슬라이드 인덱스를 기반으로 추가 작업 수행
    }

    const initSwiper = () => {
        swiperRef.current?.destroy(true, true);
        swiperDetailRef.current?.destroy(true, true);

        SwiperInit.use([Pagination, Navigation])
        swiperRef.current = new SwiperInit(".detail-top .media-content .slide-wrap", {
            navigation: {
                nextEl: ".btn-next",
                prevEl: ".btn-prev",
            },
            pagination: {
                el: ".slide-page",
                type: "fraction",
            },
            loop: true,
        });

        swiperDetailRef.current = new SwiperInit(".media-modal .slide-wrap", {
            navigation: {
                nextEl: ".modal-btn-next",
                prevEl: ".modal-btn-prev",
            },
            loop: true
        });
    }

    return (
        <div className="detail-top">
            <div className="media-content">
                {
                    boardVO.boardType === 'card' &&
                    <div id={"CARDObject"} className="slide-wrap">
                        <ul className="slide-list swiper-wrapper">
                            {
                                cardFileList.map((item: BoardFileVO, index: number) => (
                                    <li key={index} className="slide-item swiper-slide">
                                        <a className={"btn-media card-modal"} style={{width: '100%'}}>
                                            <img src={FileUtils.getImageSrc(item)}
                                                 alt=""
                                                 className="img-cardnews" style={{
                                                objectFit: "contain",
                                                width: "100%"
                                            }}/>
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="slide-page"></div>
                        <div className="btn-prev"></div>
                        <div className="btn-next"></div>
                    </div>
                }
                {
                    boardVO.boardType === 'pdf' && ebook &&
                    <M_PdfViewer fileUrl={FileUtils.getFileByFidx(ebook) || ""}/>
                }
            </div>
            <div className="media-modal" style={{ display : 'none' }}>
                <div className="inner">
                    <div className="slide-wrap">
                        <ul className="slide-list swiper-wrapper">
                            {
                                cardFileList.map((item: BoardFileVO, index: number) => (
                                    <li key={index} className="slide-item swiper-slide">
                                        <a className={"btn-media card-modal"} style={{width: '100%'}}>
                                            <img
                                                onContextMenu={e=>{e.preventDefault()}}
                                                src={FileUtils.getImageSrc(item)}
                                                 alt={""}
                                                 className="img-cardnews" style={{
                                                objectFit: "contain",
                                                width: "100%"
                                            }}/>
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="slide-page"></div>
                        <div className="modal-btn-prev"></div>
                        <div className="modal-btn-next"></div>
                    </div>
                    <button type="button" className="btn-close"></button>
                </div>
            </div>
        </div>
    );
}

export default M_BoardCard;