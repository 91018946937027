import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import CodeUtils from "../../../../common/utils/code.utils";
import {useNavigate, useParams} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import MemberUtils from "../../../../common/utils/member.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";

/**
 * 게시판 아이템
 * @param board
 * @param isNeedLogin : 로그인이 필수인 경우에 로그인 confirm 창을 표출합니다.
 * @constructor
 */
const M_BasicItem: React.FC<{ board: BoardVO, isNeedLogin: boolean, boardInfo?: BoardInfoVO }> = ({
                                                                                                      board,
                                                                                                      isNeedLogin,
                                                                                                      boardInfo
                                                                                                  }) => {

    let {boardKey} = useParams();
    const [coName, setCoName] = React.useState<string>("");
    const navigate = useNavigate();


    useEffect(() => {
    
        if ( board.writerInfo ) {
            CodeUtils.getCoCodeNames(board.writerInfo.coCode)
                .then(names => {
                    setCoName(names);
                })
                .catch(error => {
                    console.error('M_Error fetching code names:', error);
                    setCoName('Failed to load names');
                });
        }
    }, [board.coCode]);

    // TODO: 테스트용
    useEffect(() => {
        console.log("isNeedLogin : ", isNeedLogin)
    }, [isNeedLogin]);

    const checkLogin = () => {
        if (MemberUtils.getSessionVO() == null) {
            if (window.confirm('로그인이 필요합니다. 로그인 페이지로 넘어가시겠습니까?')) {
                navigate('/login');
            }
        } else {
            goView()
        }
    }

    const goView = () => {
        // StringUtils.filterEmpty 함수를 사용하여 타이틀이 비어있지 않은지 확인
        const title = StringUtils.titleUrlFilter(board.title) || 'default-title';

        if (board.boardKey == "use_story") {
            // story/:boardKey/:bidx/:boardTitle
            navigate(`/board/story/${board.boardKey}/${board.idx}/${encodeURIComponent(title)}`);
        } else {
            navigate(`/board/${board.boardKey}/${board.idx}/${encodeURIComponent(title)}`);
        }

    };

    const removeText = (text?:String) => {
        const updatedText = text?.replace(/후기/g, "");
        return updatedText
    };

    return (<>{
        boardKey != "use_story"
            ? <li style={{cursor: "pointer"}} onClick={isNeedLogin ? checkLogin : goView}>
                <a href="#" onClick={(event)=> event.preventDefault()}>
                    <div className="cate">
                        <span>{coName}</span>
                        {
                            (!boardInfo || boardInfo.isTarget) &&
                            <span>{board.jobName}</span>
                        }
                    </div>
                    <strong className="tit">{board.title}</strong>
                    <div className="info-wrap">
                        <div>
                            <div className="writer">
                                {board.writer}
                            </div>
                            <span className="date">{DateUtils.formatDate(board.createDate)}</span>
                        </div>
                        <div>
                            {board.isFile && <span className="attach"></span>}
                            <span className="hits">{board.views}</span>

                            {
                                board.reviewCount > 0 && (<span className="comment">({board.reviewCount})</span>)
                            }

                        </div>
                    </div>
                </a>
            </li>
            :
            <li style={{cursor: "pointer"}} onClick={isNeedLogin ? checkLogin : goView}>
                <a href="#" onClick={(event) => event.preventDefault()}>
                    <div className="cate">
                        {board.isNotice && <span className="badge type1 sm red">BEST</span>}
                        <span>{removeText(board.cateName ?? "")}</span>
                    </div>
                    <strong className="tit">{board.title}</strong>
                    <p className="desc">{board.contentText}</p>
                    <div className="info-wrap">
                        <div>
                            <span className="writer">{board.writer}</span>
                        </div>
                        <div>
                            <span className="date">{DateUtils.formatDate(board.createDate)}</span>
                        </div>
                    </div>
                </a>
            </li>
    }</>)
}


export default M_BasicItem;