import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import {Link} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import FileUtils from "../../../../common/utils/file.utils";
import APIs from "../../../../common/apis/APIs";
import {EduContentVO, EduMainVO} from "../../../../core/education/education.vo";

const M_UseStoryCard: React.FC<{eduContent: EduContentVO}> = ({eduContent}) => {
    

    return (
        <>
            {/* 코스학습 카드 */}
            {
                eduContent &&
                <div className="selected-content">
                    <strong className="content-tit">코스학습</strong>
                    <div className="content-wrap">
                        <Link to={`javascript:;`}>
                            { eduContent.contentType === `mov` &&
                                <>
                                    <div className="image-wrap">
                                        <img src={eduContent.qstMovVO.movThumb} alt={eduContent.qstMovVO.movTitle} onError={FileUtils.onErrorImg} className="img-profile"/>
                                    </div>
                                    <div className="text-wrap">
                                        <strong className="name">{eduContent.qstMovVO.movTitle}</strong>
                                        <span className="job">{eduContent.eduMainVO.eduTitle}</span>
                                    </div>
                                </>
                            }
                            { eduContent.contentType === `card` &&
                                <div className="text-wrap">
                                    <strong className="name">{eduContent.qstCardVO.cardName}</strong>
                                    <span className="job">{eduContent.eduMainVO.eduTitle}</span>
                                </div>
                            }
                        </Link>
                    </div>
                </div>
            }
        </>
    );
}

export default M_UseStoryCard;