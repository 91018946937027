import React, {useEffect, useRef, useState} from "react";
import Snb from "../Snb";
import {BoardFileVO, BoardVO, DefaultBoardInfoVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import SummernoteIframe, {SummernoteIframeHandle} from "../../../pc/common/SummernoteIframe";
import FileUploader from "../../../pc/common/FileUploader";
import APIs from "../../../../common/apis/APIs";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import UseStorySelect from "./UseStorySelect";
import BoardService from "../../../../core/board/board.service";
import {SessionVO} from "../../../../core/common/session.vo";
import {useNavigate, useParams} from "react-router-dom";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import FileUtils from "../../../../common/utils/file.utils";
import ProService from "../../../../core/pro/pro.service"
import ElandUtils from "../../../../common/utils/eland.utils";
import StringUtils from "../../../../common/utils/string.utils";
import {EduContentVO, EduMainVO} from "../../../../core/education/education.vo";
import EducationService from "../../../../core/education/education.service";
import UseStorySelectEdu from "./UseStorySelectEdu";

const useStoryForm: React.FC = () => {
	let {boardKey, bidx} = useParams();
	const navigate = useNavigate()
	
	const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO);
	const [selectedBoardVO, setSelectedBoardVO] = useState<BoardVO | null>();
	const [contentList, setContentList] = useState<BoardVO[]>([]);
	const [selectedEduContentVO, setSelectedEduContentVO] = useState<EduContentVO | null>()
	const [eduContentList, setEduContentList] = useState<EduContentVO[]>([])
	const [proList, setProList] = useState<ProReqVO[]>([]);
	const [params, setParams] = useState<HWMap>(new PageUtils())
	const [totalContents, setTotalContents] = useState<number>(0);
	const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
	const [isSelectActive, setIsSelectActive] = useState(false);
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [keyIndex, setKeyIndex] = useState(0);
	
	const iframeRef = useRef<SummernoteIframeHandle>(null);
	const $uploadRef = useRef()
	const boardService = new BoardService();
	
	useEffect(() => {
		if (!bidx) return;
		(async () => {
			const boardNum = parseInt(bidx, 10)
			
			if (boardNum <= 0) {
				// 임시저장 게시물 있는지 확인
				const result = await boardService.getRecentTempBoardOne({boardKey: boardKey});
				// console.log("⭐getRecentTempBoardOne", result);
				if (result.status == "SUCCESS" && result.data.boardVO != null) {
					setBoardVO(result.data.boardVO);
					getSelectBoard(result.data.boardVO);
				} else {
					// 없으면 새로 생성
					let result = await BoardService.getBoardInfoOne({boardKey});
					if (result.status === 'ok' && result.boardInfoVO != null){
						const defaultText = result.boardInfoVO.defaultText ? StringUtils.nl2br(result.boardInfoVO.defaultText) : "";
						setBoardVO({...boardVO, cateCode: "040501", contentHtml : defaultText})
					}
				}
				
				resetParams()
			} else {
				const result = await boardService.getBoardOne({bidx: bidx, isSaveHis: false});
				
				if (result.status == "SUCCESS" && result.data) {
					setBoardVO(result.data.boardVO);
					getSelectBoard(result.data.boardVO);
				}
			}
			
			setKeyIndex(keyIndex + 1);
		})()
		
		resetParams()
	}, [bidx]);
	
	// 선택한 적용스토리 조회
	/**
	 * 수정한 화면에 사용될 선택된 콘텐츠 조회 용도
	 * @param boardVO
	 */
	const getSelectBoard = (boardVO: BoardVO) => {
		(async () => {
			// 지식콘텐츠
			if ("040501" === boardVO.cateCode) {
				const result = await boardService.getBoardOne({bidx: boardVO.opt5, isSaveHis: false});
				if (result.status == "SUCCESS" && result.data) {
					setSelectedBoardVO(result.data.boardVO);
				}
			}
			// 사내고수라면
			if ("040502" === boardVO.cateCode) {
				const successHandler = async (result: any) => {
					let selectedBoardVO: BoardVO = DefaultBoardVO();
					selectedBoardVO.receiverProInfo = result.data.pro;
					selectedBoardVO.receiverProInfo.jobCodeName = await ElandUtils.convertJobSkillCodesToNames(selectedBoardVO.receiverProInfo.jobCode)
					setSelectedBoardVO(selectedBoardVO);
				}
				await ProService.proInfoGet(boardVO.opt5 || "0", successHandler)
			}
			// 코스학습
			if ( "040504" === boardVO.cateCode ) {
				const contentVO:EduContentVO = await EducationService.getEduContent( Number(boardVO.opt5) )
				setSelectedEduContentVO(contentVO)
			}
		})()
	}
	
	
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		resetParams()
		setSelectedBoardVO(null)
		setSelectedEduContentVO(null)
		setBoardVO({
			...boardVO,
			cateCode: event.target.value
		});
	};
	
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;
		if (value.length <= 25) {  // 제목 25글자 제한
			setBoardVO(prevState => ({
				...prevState,
				[name]: value
			}));
		}
	};
	
	const handleUploadFile = (idx: string) => {
		const parsed = parseInt(idx, 10)
		const thumbIdx = isNaN(parsed) ? null : parsed;
		setBoardVO({...boardVO, thumbIdx: thumbIdx});
	}
	
	const resetParams = () => {
		setParams({
			page: 0,
			size: 5,
			sort: "createDate,DESC",
		});
	}
	
	const validateForm = (isTemp: boolean): boolean => {
		if (!sessionVO) {
			ToastUtils.show("로그인이 필요합니다!", 2)
			return false;
		}
		if ( (!selectedBoardVO && !selectedEduContentVO) && boardVO.cateCode != "040503" ) {
			ToastUtils.show("목적을 선택해주세요.", 2)
			return false;
		}
		if (boardVO.title.trim() === "") {
			ToastUtils.show("제목을 작성해주세요.", 2)
			return false;
		}
		// if (!isTemp && (!boardVO || !boardVO.thumbIdx || boardVO.thumbIdx <= 0)) {
		//     ToastUtils.show("첨부파일을 선택해주세요.", 2 )
		//     return false;
		// }
		
		const htmlContent = iframeRef.current?.getHtmlContent();
		if (!htmlContent || htmlContent.trim() === "" || htmlContent.trim() === "<br>") {
			ToastUtils.show("내용을 작성해주세요.", 2)
			return false;
		}
		return true;
	};
	
	
	const saveForm = (isTemp: boolean) => {
		
		if (isSubmitting) return;
		setIsSubmitting(true);
		
		if (validateForm(isTemp)) {
			const htmlContent = iframeRef.current?.getHtmlContent();
			
			// 지식콘텐츠 경우 : bidx, 사내고수 도움 후기 : proIdx, 활동 후기 : 본인 idx
			/**
			 * 지식콘텐츠 경우 : bidx
			 * 사내고수 도움 후기 : proIdx
			 * 활동 후기 : 본인 idx
			 * 코스학습 콘텐츠 후기 : ecIdx
			 */
			let optNum;
			if (boardVO.cateCode == '040501') {
				optNum = selectedBoardVO?.idx
			} else if (boardVO.cateCode == '040502') {
				optNum = selectedBoardVO?.receiverProInfo?.proidx;
			} else if (boardVO.cateCode == '040503') {
				optNum = MemberUtils.getSessionVO()?.caidx
			} else if (boardVO.cateCode == '040504') {
				optNum = selectedEduContentVO?.ecIdx
			}
			
			
			const saveBoard = {
				...boardVO,
				idx: boardVO.isTemp && !isTemp ? 0 : boardVO.idx,
				contentHtml: htmlContent,
				opt5: optNum,
				writer: sessionVO?.userName,
				writerKey: sessionVO?.coEmailId,
				boardKey: "use_story",
				boardType: "html",
				fileIdxs: boardVO.thumbIdx,
				isTemp: isTemp,
				coCode: MemberUtils.getSessionVO()?.coCode // 작성자 법인
			};
			
			const {createDate, updateDate, ...filteredSaveBoard} = saveBoard;
			
			boardService.saveBoardOne(filteredSaveBoard)
				.then(async (res) => {
					if (res.status === "SUCCESS") {
						const resultBoard = res.data;
						if (isTemp) {
							setBoardVO(resultBoard);
							ToastUtils.show("임시저장이 완료되었습니다.", 2)
						} else {
							// 포인트 저장
							await PointService.savePoint("STORY_CREATE", resultBoard.idx)
							ToastUtils.show("저장이 완료되었습니다.", 2)
							navigate("/board/story/use_story")
						}
					} else {
						console.log(":::" + res.status, res.msg);
					}
					
				});
		}
		
		setIsSubmitting(false);
	};
	
	
	const updateParams = (page: number) => {
		if (boardVO.cateCode == '040501' && contentList.length == 0) {
			return;
		} else if (boardVO.cateCode == '040502' && proList.length == 0) {
			return;
		}
		
		// 페이지 당 수
		const itemsPerPage = 5;
		// 마지막 페이지를 올림으로 계산하여 0부터 시작하는 인덱스에 맞추기 위해 -1
		const lastPage = Math.ceil(totalContents / itemsPerPage) - 1;
		
		if (page < 0 || page > lastPage) {
			console.warn("페이지가 유효한 범위를 벗어났습니다. 반환됩니다.");
			return;
		}
		
		setParams({...params, page: page})
	}
	
	
	useEffect(() => {
		if (!boardVO.cateCode) return;
		
		(async () => {
			if (boardVO.cateCode == "040501") {
				const result = await boardService.getRecentViewList({...params, boardKeys: APIs.KNOWLEDGE_BOARDKEY});
				setContentList(result.data.content);
				setTotalContents(result.data.totalElements);
			}
			
			if (boardVO.cateCode == "040502") {
				const successHandler = (result:any) => {
					setProList(result.data.list);
					setTotalContents(result.data.totalCount);
				}
				await ProService.getInquiryReceiver({...params, statusCode: "041904,041903"}, successHandler)
			}
			// 사내고수로서 활동한 후기는 조회 안함
			if (boardVO.cateCode == "040503") {
				setContentList([]);
				setProList([]);
				setTotalContents(0);
				setIsSelectActive(false);
			}
			// 코스학습 목록 조회
			if (boardVO.cateCode == "040504") {
				params.userId = sessionVO?.coEmailId
				params.contentType = "mov,card"
				params.isDisplay = true
				params.openCode = "20010201,20010202,20010203"
				params.cateCode = ""
				params.days = 365
				params.isComplete = true
				params.sort = "updateDate,DESC"
				const mineEduData = await EducationService.getEduContentAnswerList(params)
				console.log("🛠️ mineEduData : ", mineEduData)
				setEduContentList(mineEduData.list)
				setTotalContents(mineEduData.count)
			}
		})()
		
	}, [params]);
	
	useEffect(() => {
		console.log("⭐selectBoardVO", selectedBoardVO);
	}, [selectedBoardVO]);
	
	useEffect(() => {
		console.log("🅱️selectEduMainVO", selectedEduContentVO);
	}, [selectedEduContentVO]);
	
	return (
		<main className="content register nav-layout">
			<div className="common-title">
				<div className="inner">
					<h2 className="tit">적용스토리 등록</h2>
					<p className="desc">
						적용 스토리를 들려주세요. 인터뷰 질문이 미리 작성되어 있지만 꼭 양식대로 작성하지 않으셔도 돼요!<br/>
						다른 사람들에게 도움이 될만한 내용도 좋고, 감사편지를 남기셔도 좋아요. 베스트 후기에 선정되시면 2,000p를 지급해 드려요🎁
					</p>
				</div>
			</div>
			<section className="form-section">
				<div className="inner">
					<div className="form-list use-story">
						<div className="form-item">
							<div className="form-title">
								<strong className="tit">목적</strong>
							</div>
							<div className="rdo-bind">
								<div className="radio col-6">
									<input type="radio" name="radios" id="radioId" className="inp-radio"
									       disabled={bidx != "0"}
									       value="040501" checked={boardVO?.cateCode === '040501'}
									       onChange={handleRadioChange}/>
									<label htmlFor="radioId" className="lab-radio">지식 콘텐츠에 대한 후기를 남길게요</label>
								</div>
								<div className="radio col-6">
									<input type="radio" name="radios" id="radioId2" className="inp-radio"
									       disabled={bidx != "0"}
									       value="040502" checked={boardVO?.cateCode === '040502'}
									       onChange={handleRadioChange}/>
									<label htmlFor="radioId2" className="lab-radio">사내고수에게 활동한 후기를 남길게요</label>
								</div>
							</div>
							<div className="rdo-bind mt-3">
								<div className="radio col-6">
									<input type="radio" name="radios" id="radioId4" className="inp-radio"
									       disabled={bidx != "0"}
									       value="040504" checked={boardVO?.cateCode === '040504'}
									       onChange={handleRadioChange}/>
									<label htmlFor="radioId4" className="lab-radio">코스학습 콘텐츠에 대한 후기를 남길게요</label>
								</div>
								<div className="radio col-6">
									<input type="radio" name="radios" id="radioId3" className="inp-radio"
									       value="040503" checked={boardVO?.cateCode === '040503'}
									       onChange={handleRadioChange} disabled={bidx != "0" || !MemberUtils.isPro()}/>
									<label htmlFor="radioId3" className="lab-radio">사내고수로서 활동한 후기를 남길게요</label>
								</div>
							</div>
							
							{/* 목적 선택 항목 */}
							<div className="sel-bind fill">
								<div className={`select ${isSelectActive ? 'active' : ''}`}>
									<button type="button" className="btn-select"
									        onClick={() => setIsSelectActive(!isSelectActive)}
									        disabled={bidx != "0" || boardVO?.cateCode === '040503'}>선택해주세요
									</button>
									<div className="select-content">
										{
											// 지식콘텐츠
											boardVO?.cateCode && ["040501", "040502", "040503"].includes(boardVO?.cateCode) &&
											<UseStorySelect
												contentList={contentList || []}
												proList={proList || []}
												isPro={boardVO?.cateCode === "040502"}
												selectBoard={selectedBoardVO || null}
												setSelectBoard={(board) => {
													setSelectedBoardVO(board);
													setIsSelectActive(false); // 'active' 클래스 제거
												}}
											/>
										}
										
										{
											// 코스학습
											boardVO?.cateCode === "040504" &&
											<UseStorySelectEdu
												contentList={eduContentList || []}
												selectEduContent={selectedEduContentVO || null}
												setSelectEduContent={(eduContent) => {
													setSelectedEduContentVO(eduContent);
													setIsSelectActive(false); // 'active' 클래스 제거
												}}
											/>
										}
										
										{
											( (boardVO.cateCode == "040501" && contentList.length > 0) || (boardVO.cateCode == "040502" && proList.length > 0) || (boardVO.cateCode == "040504" && eduContentList.length > 0) )
											&&
											<div className="button-area">
												<a style={{cursor: 'pointer' }} className="btn-prev"
												   onClick={() => updateParams(params.page - 1)}><span
													className="blind">이전</span></a>
												<a style={{cursor: 'pointer' }} className="btn-next"
												   onClick={() => updateParams(params.page + 1)}><span
													className="blind">이전</span></a>
											</div>
										}
									</div>
								</div>
							</div>
							
							{/* 선택한 목적 표출 */}
							{
								selectedBoardVO &&
								<div className="selected-preview">
									<ul className="preview-list">
										{
											boardVO?.cateCode == '040501' &&
											<li>
												<div className="image-wrap">
													<img src={selectedBoardVO.boardKey =='job_data' ? FileUtils.getRandomJobDataImg(selectedBoardVO) : FileUtils.getImageSrc(selectedBoardVO.thumbFile)}
													     alt="선택한 적용스토리 썸네일" className="img-thumb"/>
												</div>
												<div className="text-wrap">
													<strong className="tit">{selectedBoardVO.title}</strong>
													<div className="info">
														<span>{selectedBoardVO.writer}</span>
													</div>
												</div>
												<button type="button" className="btn-close" disabled={bidx != "0"} onClick={() => setSelectedBoardVO(null)}></button>
											</li>
										}
										{
											boardVO?.cateCode == '040502' &&
											<li>
												<div className="image-wrap">
													<img
														src={selectedBoardVO.receiverProInfo.profileImgSrc}
														alt="사내고수 프로필 사진" className="img-thumb"/>
												</div>
												<div className="text-wrap">
													<strong
														className="tit">{selectedBoardVO.receiverProInfo.companyAccountVO?.userName}</strong>
													<div className="info">
														<span>{selectedBoardVO.receiverProInfo.jobCodeName}</span>
													</div>
												</div>
												<button type="button" className="btn-close" disabled={bidx != "0"} onClick={() => setSelectedBoardVO(null)}></button>
											</li>
										}
									</ul>
								</div>
							}
							
							{/* 선택한 코스학습 */}
							{
								selectedEduContentVO &&
								<div className="selected-preview">
									<ul className="preview-list">
										{
											boardVO?.cateCode == '040504' &&
											<li>
												{ selectedEduContentVO.contentType === `mov` &&
													<>
														<div className="image-wrap">
															<img src={selectedEduContentVO.qstMovVO.movThumb} alt={selectedEduContentVO.qstMovVO.movTitle} className="img-thumb" />
														</div>
														<div className="text-wrap">
															<strong className="tit">{selectedEduContentVO.qstMovVO.movTitle}</strong>
															<div className="info">
																<span>{selectedEduContentVO.eduMainVO.eduTitle}</span>
															</div>
														</div>
													</>
												}
												{ selectedEduContentVO.contentType === `card` &&
													<>
														<div className="text-wrap">
															<strong className="tit">{selectedEduContentVO.qstCardVO.cardName}</strong>
															<div className="info">
																<span>{selectedEduContentVO.eduMainVO.eduTitle}</span>
															</div>
														</div>
													</>
												}
												<button type="button" className="btn-close" disabled={bidx != "0"} onClick={() => setSelectedEduContentVO(null)}></button>
											</li>
										}
									</ul>
								</div>
							}
							
						</div>
						<div className="form-item">
							<div className="form-title">
								<strong className="tit">제목</strong>
							</div>
							<div className="inp-bind fill">
								<input type="text" name="title" id="" className="inp-text"
								       value={boardVO?.title}
								       onChange={handleInputChange}
								       placeholder="제목을 입력해주세요"/>
							</div>
						</div>
						<div className="form-item">
							<div className="form-title">
								<strong className="tit">내용</strong>
							</div>
							<SummernoteIframe mode={"edit"} ref={iframeRef}
							                  htmlCode={boardVO?.contentHtml || boardVO?.contentText}/>
						</div>
						<div className="form-item">
							<div className="form-title type-tooltip">
								<strong className="tit">대표사진</strong>
								<div className="tooltip">
									<button type="button" className="btn-tooltip"><span className="blind">툴팁</span>
									</button>
									<div className="tooltip-content">
										<div className="txt">
											대표사진은 베스트후기 선정 시 프로필사진으로 설정됩니다
										</div>
										<button type="button" className="btn-close"><span className="blind">닫기</span>
										</button>
									</div>
								</div>
							</div>
							{
								boardVO.thumbIdx &&
								<div className="media-preview">
									<img src={APIs.BOARD_FILES_SHOW + boardVO?.thumbIdx} alt=""
									     className="img-thumb"/>
								</div>
							}
							{/*<input type="file" name="" id="" className="inp-file"/>*/}
							{/*<input type="text" name="" id="" className="inp-text inp-upload"*/}
							{/*       placeholder="대표사진을 설정해주세요"/>*/}
							{/*<button type="button" className="btn gray btn-upload">첨부파일 찾기</button>*/}
							
							<FileUploader
								key={keyIndex}
								ref={$uploadRef} params={{
								domId: `boardVO-${boardVO.idx}`,
								target: `board`,
								fidxs: boardVO.thumbIdx,
								maxFileSize: 20000000,
								maxFileCount: 1,
								denyList: 'mp4,wmv,avi,gif,exe,xls,ppt,webp,pptx,doc,docx,txt,pdf,zip,rar,7z,html,css,js,' +
									'json,xml,flv,mkv,mov,mpg,mpeg,mp3,wav,aac,ogg,iso,dmg,bin,bat,sh,tar,gz,psd,ai,eps',
								updateHandler: (_target: string, _files: string) => {
									console.log(_target + " ::::: " + _files)
									handleUploadFile(_files);
								}
							}}/>
							
							<div className="guide-text">
								파일 포맷 : JPG, JPEG, PNG / 파일크기 : 20MB이하 | 가로-세로 4:3비율 권장
							</div>
						</div>
					</div>
					<div className="button-area">
						{
							(boardVO.idx == 0 || boardVO.isTemp) &&
							<button type="button" className="btn gray lg" onClick={() => saveForm(true)}>임시저장</button>
						}
						<button type="button" className="btn red lg" onClick={() => saveForm(false)}>등록하기</button>
					</div>
				</div>
			</section>
			
			{/* side menu */}
			<Snb/>
		
		</main>
	);
}

export default useStoryForm;