import React, {useEffect, useState} from "react";
import axiosCaller from "../../../common/utils/axios.caller";
import APIs from "../../../common/apis/APIs";
import {useDispatch} from "react-redux";
import {setSession} from "../../../common/redux/slices/sessionSlice";
import {useLocation, useNavigate} from "react-router-dom";
import MemberUtils from "../../../common/utils/member.utils";
import PointService from "../../../core/point/point.service";


function Login (props: {reload : () => void }) {

	const navigate = useNavigate()
	const location = useLocation();
	const dispatch = useDispatch()

	const [userid, setUserid] = useState<string>("")
	const [passwd, setPasswd] = useState<string>("")
	const [isRemember, setIsRemember] = useState<boolean>(localStorage.getItem("isRemember") === 'true')

	const [isCorrect, setIsCorrect] = useState(true)
	const [isUse, setIsUse] = useState(true)


	const doLogin = async (e :any) => {
		e.preventDefault()

		const result = await axiosCaller.post(APIs.SESSION + "/doLogin", {
			userid :userid,
			passwd :passwd,
		})
		
		// 이랜드 API 문제로 로그인 불가시 점검 페이지로 이동
		if ( result.status === "FAIL_API" ) {
			setIsCorrect(false)
			setIsUse(true)
			setPasswd("")
			navigate("/checkService", { replace: true });
			return
		}
		
		// 비밀번호 오류
		if ( result.status === "FAIL" ) {
			setIsCorrect(false)
			setIsUse(true)
			setPasswd("")
			return
		} else {
			setIsCorrect(true)
		}
		
		// 비활성화 된 사용자
		if ( result.status === "FAIL_NO_USE" ) {
			setIsUse(false)
			setPasswd("")
			return
		}

		if ( result.status === "SUCCESS" ) {
			setIsCorrect(true)
			setIsUse(true)
			dispatch(setSession({
				sessionVO: result.coAccountVO,
				token: result.token,
			}))

			// 리멤버 기능
			doRememberMe(userid)

			// 로그인시 포인트 적립
			await PointService.savePoint("LOGIN")

			MemberUtils.setIsCompleteJobSkill(result.isCompleteJobSkill)
			props.reload()
			redirectByHistory()
		}

	}

	const doRememberMe = (userid:string) => {
		if ( isRemember ) {
			localStorage.setItem("isRemember", "true");
			localStorage.setItem("rememberUserId", userid);
		} else {
			localStorage.removeItem("isRemember");
			localStorage.removeItem("rememberUserId");
		}
	}

	const redirectByHistory = () => {
		if (location.key === "default") {
			navigate("/", { replace: true });
		} else {
			navigate(-1);
		}
	}


	useEffect(() => {
		if ( localStorage.getItem("isRemember") === "true" ) {
			setUserid(localStorage.getItem("rememberUserId") || "")
		}

	}, [])


	return (
		<main className="content login">
			<form onSubmit={doLogin}>
			<section className="login-section">
				<div className="common-title">
					<h2 className="tit">로그인</h2>
					<p className="desc">아이디와 비밀번호는 이랜드 계정과 동일합니다</p>
				</div>
				<div className="form-list">
					<div className="form-item">
						<div className="form-title">
							<strong className="tit">아이디</strong>
						</div>
						<div className="inp-bind">
							<input type="text" name="" id="userid" className="inp-text" placeholder="아이디를 입력하세요"
								   value={userid} onChange={e=>setUserid(e.target.value)} />
						</div>
					</div>
					<div className="form-item">
						<div className="form-title">
							<strong className="tit">비밀번호</strong>
						</div>
						<div className="inp-btn-bind type-password">
							<input type="password" name="" id="passwd" className="inp-password" placeholder="비밀번호를 입력하세요"
								   value={passwd} onChange={e=>setPasswd(e.target.value)}/>
								<button type="button" className="btn-change"></button>
						</div>
					</div>
				</div>

				{
					!isCorrect &&
					<div className="error-text">
						아이디 혹은 비밀번호가 일치하지 않습니다.<br />계정 비밀번호 변경은 id.eland.co.kr 에서 하실 수 있습니다.
					</div>
				}

				{
					!isUse &&
					<div className="error-text">
						비활성화된 사용자입니다.<br />관리자에게 문의하세요.
					</div>
				}

				<div className="button-area">
					<button type="submit" className="btn red" onClick={doLogin}>로그인</button>
				</div>
				<div className="login-keep">
					<div className="checkbox">
						<input type="checkbox" name="keepID" id="keepID" className="inp-checkbox"
							   checked={isRemember} onChange={e=>setIsRemember(e.target.checked)} />
							<label htmlFor="keepID" className="lab-checkbox">아이디 기억하기</label>
					</div>
				</div>
			</section>
			</form>
		</main>
	)
}


export default Login