import React, {useEffect, useRef, useState} from "react";
import {BoardVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import SwalUtils from "../../../../common/utils/swal.utils";
import {EduContentVO, EduMainVO} from "../../../../core/education/education.vo";

interface UseStoryEduSelectProps {
    contentList: EduContentVO[],
    selectEduContent: EduContentVO | null,
    setSelectEduContent:(eduContent: EduContentVO) => void;
    cancelEvent:()=>void
}

const M_UseStorySelectEdu: React.FC<UseStoryEduSelectProps> = ({
                                                             contentList,
                                                             selectEduContent,
                                                             setSelectEduContent,
                                                             cancelEvent
                                                         }) => {

    const [updatedContentList, setUpdatedContentList] = useState<BoardVO[]>([]);
    const [activeItem, setActiveItem] = useState<EduContentVO | null>();
    
    
    
    function isEduMain(item: any): item is EduMainVO {
        return item && typeof item.emIdx === 'number';
    }
    
    
    const viewAlert = () => {
        SwalUtils.text({
            title: "적용스토리",
            message: `대상을 선택해주세요.`
        })
    }
    
    
    const selectItem = () => {
        if (activeItem) {
            if (isEduMain(activeItem)) {
                setTranslateY(0)
                setSelectEduContent(activeItem);
            } else {
                viewAlert()
            }
        }
    }
    
    
    useEffect(() => {
        setActiveItem(null)
        
        if (selectEduContent) {
            setActiveItem(selectEduContent)
        }
    }, [contentList]);


    // 상단 바 끌어 내려 취소하는 기능 추가
    const [translateY, setTranslateY] = useState(0);
    const startYRef = useRef<number | null>(null);

    useEffect(() => {
        const prevScrollY = preventScroll();
        return () => {
            allowScroll(prevScrollY);
        };
    }, []);

    const preventScroll = () => {
        const currentScrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
        document.body.style.overflowY = 'scroll';
        document.body.style.overflow = "hidden";
        document.body.style.overscrollBehaviorY = 'contain'
        return currentScrollY;
    };

    const allowScroll = (prevScrollY: number) => {
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        document.body.style.overflowY = '';
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorY = ''
        window.scrollTo(0, prevScrollY);
    };


    const checkDragEleClick = (target: HTMLDivElement, x: number, y: number) => {
        const rect = target.getBoundingClientRect();

        // const pseudoElementLeft = rect.left + rect.width / 2 - 20;
        // const pseudoElementWidth = 40;
        // const xCheck = x >= pseudoElementLeft && x <= pseudoElementLeft + pseudoElementWidth

        const pseudoElementTop = rect.top + 16;
        const pseudoElementHeight = 20;
        const yCheck = y >= pseudoElementTop - pseudoElementHeight && y <= pseudoElementTop + pseudoElementHeight

        return yCheck

    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        startYRef.current = event.clientY - translateY;
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (startYRef.current !== null) {
            const newTranslateY = event.clientY - startYRef.current;
            if (newTranslateY >= 0) {
                setTranslateY(newTranslateY);
            }
        }
    };

    const handleMouseUp = () => {
        if (translateY > 100) {
            setTranslateY(0);
            cancelEvent();
        } else {
            setTranslateY(0);
        }
        startYRef.current = null;
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        const touch = event.touches[0];
        if (checkDragEleClick(event.currentTarget, touch.clientX, touch.clientY)) {
            startYRef.current = touch.clientY - translateY
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (startYRef.current !== null) {
            const touch = event.touches[0];
            const newTranslateY = touch.clientY - startYRef.current;

            if (newTranslateY >= 0) {
                setTranslateY(newTranslateY)
            }
        }
    }

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (translateY > 100) {
            setTranslateY(0)
            cancelEvent()

        } else {
            setTranslateY(0)
        }
        startYRef.current = null;
    };


    return (
        <div className={`layer type-half content-select`}
             style={{
                 display: 'block',
                 transform: `translateY(${translateY}px)`,
                 transition: translateY === 0 ? 'transform 0.3s ease' : 'none',
             }}>
    
            <div className="layer-content"
                 onMouseDown={handleMouseDown}
                 onMouseMove={handleMouseMove}
                 onMouseUp={handleMouseUp}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}>
                <div className="inner">
                    <div className="layer-title">
                        <h3 className="tit">코스학습 선택</h3>
                    </div>
                    <div className="scroll-content">
                        {contentList.length === 0 ? (
                            <div className="content-empty">
                                <p className="txt">
                                    진행한 코스학습 콘텐츠가 없습니다.
                                    <br/>
                                    코스학습 콘텐츠를 진행한 후, 적용스토리를 작성해주세요
                                </p>
                            </div>
                        ) : (
                            <ul className="content-list">
                                {contentList.map((item, index) => (
                                    <li className={`${isEduMain(activeItem) && activeItem.ecIdx === item.ecIdx && 'selected'}`} key={index} onClick={() => setActiveItem(item)}>
                                        <a href="#">
                                            { item.contentType === `mov` &&
                                                <>
                                                    <div className="image-wrap">
                                                        <img src={item.qstMovVO.movThumb} alt={item.qstMovVO.movTitle} className="img-thumb" />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <strong className="tit">
                                                            {item.qstMovVO.movTitle}
                                                        </strong>
                                                        <div className="info">
                                                            <span>{item.eduMainVO.eduTitle}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            { item.contentType === `card` &&
                                                <div className="text-wrap">
                                                    <strong className="tit">
                                                        {item.qstCardVO.cardName}
                                                    </strong>
                                                    <div className="info">
                                                        <span>{item.eduMainVO.eduTitle}</span>
                                                    </div>
                                                </div>
                                            }
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>

            <div className="layer-button">
                <div className="inner">
                    <div className="button-area">
                        <button type="button" className="btn lg full" onClick={selectItem}
                                disabled={!activeItem}>
                            등록하기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default M_UseStorySelectEdu;