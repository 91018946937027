import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import BoardService from "../../../../core/board/board.service";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import {SessionVO} from "../../../../core/common/session.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import SummernoteIframe from "../../../pc/common/SummernoteIframe";
import {CompanyAccountVO} from "../../../../core/company/company.vo";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import SwalUtils from "../../../../common/utils/swal.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import FileUtils from "../../../../common/utils/file.utils";
import ElandUtils from "../../../../common/utils/eland.utils";
import EducationService from "../../../../core/education/education.service";
import {EduContentVO, EduMainVO} from "../../../../core/education/education.vo";

const useStoryDetail: React.FC = () => {

    const boardService = new BoardService();
    const navigate = useNavigate();
    let {boardKey, bidx, boardTitle} = useParams();

    const [boardVO, setBoardVO] = useState<BoardVO>();
    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [writerInfo, setWriterInfo] = useState<CompanyAccountVO>();
    const [cardContent, setCardContent] = useState<BoardVO | ProReqVO | null>(null);
    const [eduContent, setEduContent] = useState<EduContentVO | null>(null);
    const [renderKey, setRenderKey] = useState<number>(0);

    const deleteOne = (bidx : number) => {

        SwalUtils.text({
            title : `적용스토리 삭제`,
            message : `작성하신 후기를 삭제하시겠습니까?`,
            isCancel : false,
            confirmOptions : {
                text : "확인",
                eventHandler : async() => {
                    // 삭제하시겠습니까?
                    const result = await boardService.deleteBoardOne({bidx: bidx});

                    if(result.status == "SUCCESS") {
                        // 포인트 취소
                        await PointService.cancelPoint("STORY_CREATE", bidx)
                        ToastUtils.show("삭제되었습니다.", 2 )
                        window.location.href = `/board/story/${boardKey}`;
                    } else {
                        ToastUtils.show("잠시 후 다시 시도해주세요.", 2 )
                        console.error(result.msg);
                    }
                }
            },
            cancelOptions: {
                text: "취소", eventHandler: () => {
                    console.log("취소 버튼")
                }
            }
        })


    }

    useEffect(() => {
        (async () => {
            if(!bidx) return;

            const result = await boardService.getBoardOne({bidx: bidx});

            if(result.status == "SUCCESS" && result.data) {
                const board = result.data.boardVO;

                if(!board.isUse) {
                    alert("삭제된 게시물 입니다.")
                    navigate(`/board/story/${boardKey}`);
                }

                setBoardVO(board);
                setWriterInfo(result.data.writerInfo);
                setRenderKey(renderKey + 1)
                setCardContent(null)
                setEduContent(null)
            }

        })()
    }, [bidx]);

    useEffect(() => {

        if(boardVO && boardVO.opt5) {
            // 지식콘텐츠 후기
            if(boardVO.cateCode == "040501") {
                (async () => {
                    const result = await boardService.getBoardOne({bidx: boardVO.opt5, isSaveHis: false});
                    if(result.status == "SUCCESS" && result.data) {
                        let updatedBoardVO = { ...result.data.boardVO, writerInfo: result.data.writerInfo, coInfoVO: result.data.writerInfo.coInfoVO };
                        setCardContent(updatedBoardVO);
                    }
                })()
            }
            // 사내고수 도움받은 후기
            if (boardVO.cateCode == "040502") {
                (async () => {
                    const result = await axiosCaller.get(APIs.PRO_INFO + boardVO.opt5)
                    if (result.status === 'SUCCESS') {
                        let newProReq = result.data.pro;
                        newProReq.jobCodeName = await ElandUtils.convertJobSkillCodesToNames(newProReq.jobCode)
                        setCardContent(newProReq);
                    }
                })()
            }
            // 코스학습 카드 조회
            if (boardVO.cateCode == "040504") {
                (async () => {
                    const eduContentVO = await EducationService.getEduContent( Number(boardVO.opt5) )
                    console.log(eduContentVO)
                    setEduContent(eduContentVO);
                })()
            }
        }

    }, [boardVO]);

    return (
        <>{
            boardVO &&
            <main className="content apply detail nav-layout">

                <section className="detail-section">
                    <div className="inner" style={{top:-48}}>
                        <div className="detail-title">
                            <div className="cate">
                                <a href="#">{boardVO.cateVO?.codeName}</a>
                            </div>
                            <h2 className="tit">{boardVO.title}</h2>
                            <div className="info">
                                <span className="date">{DateUtils.formatDate(boardVO.createDate)}</span>
                            </div>
                            <div className="writer">
                                <div className="image-wrap">
                                    <img src={FileUtils.getFileUrl(writerInfo?.profileSrc ?? "")} alt="프로필 이미지"
                                         onError={FileUtils.onErrorImg}
                                         className="img-profile"/>
                                </div>
                                <div className="text-wrap">
                                    <span className="name"><b>{boardVO.writer}</b></span>
                                    <span className="job">{writerInfo?.coInfoVO?.coName}</span>
                                </div>
                            </div>

                            {
                                sessionVO && sessionVO.coEmailId == boardVO.writerKey &&
                                <div className="util">
                                    <Link to={`/board/story/use_story/${bidx}/${StringUtils.titleUrlFilter(boardTitle ?? "")}/form`} className="btn-modify"><span className="blind">수정하기</span></Link>
                                    <a href="javascript:;" onClick={()=>deleteOne(boardVO?.idx)} className="btn-delete"><span className="blind">삭제하기</span></a>
                                </div>
                            }

                        </div>

                        <div className="detail-content">
                            {   // 활동 후기가 아니라면 표출
                                boardVO.cateCode != "040503" &&
                                <>
                                    {/* 지식콘텐츠 카드 */}
                                    {cardContent && 'title' in cardContent &&
                                        <div className="relation-content">
                                            <strong className="relation-tit">학습한 지식콘텐츠</strong>
                                            <div className="content-wrap">
                                                <Link to={`/board/${cardContent.boardKey}/${cardContent.idx}/${StringUtils.titleUrlFilter(cardContent.title)}`}>
                                                    <div className="image-wrap">
    
                                                        <img src={ cardContent.boardKey =='job_data' ?
                                                            FileUtils.getRandomJobDataImg(cardContent) :
                                                            (cardContent.thumbFile == null ?
                                                                process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png' :
                                                                FileUtils.getImageSrc(cardContent.thumbFile)) }
                                                             alt="참조 컨텐츠 썸네일" className="img-thumb"
                                                             onError={FileUtils.onErrorImg} />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <strong className="tit">{cardContent.title}</strong>
                                                        <span className="job">
                                                        {
                                                            (cardContent.writerInfo?.isAdmin)
                                                                ? `${cardContent.writer}`
                                                                : `${cardContent.coInfoVO?.coName} ${cardContent.writer} 고수`
                                                        }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    {/* 사내고수 카드 */}
                                    {(cardContent && 'chatTopic' in cardContent) &&
                                        <div className="relation-content">
                                            <strong className="relation-tit">사내고수</strong>
                                            <div className="content-wrap">
                                                <Link to={`/pro/${cardContent.proidx}`}>
                                                    <div className="image-wrap">
                                                        <img src={cardContent.profileImgSrc || APIs.BOARD_ERROR_IMG}
                                                             alt="" className="img-thumb"
                                                             onError={FileUtils.onErrorImg} />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <strong className="tit">{cardContent.companyAccountVO?.companyName} {cardContent.companyAccountVO?.userName}</strong>
                                                        <span className="job">{cardContent.jobCodeName}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    }

                                    {/* 코스학습 카드 */}
                                    {
                                        eduContent &&
                                        <div className="relation-content">
                                            <strong className="relation-tit">코스학습</strong>
                                            <div className="content-wrap">
                                                { eduContent.contentType === `mov` &&
                                                    <Link to={`javascript:;`}>
                                                        <div className="image-wrap">
                                                            <img src={eduContent.qstMovVO.movThumb} alt={eduContent.qstMovVO.movTitle} className="img-thumb" onError={FileUtils.onErrorImg} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            <strong className="tit line-2">{eduContent.qstMovVO.movTitle}</strong>
                                                            <span className="job">{eduContent.eduMainVO.eduTitle}</span>
                                                        </div>
                                                    </Link>
                                                }
                                                { eduContent.contentType === `card` &&
                                                    <Link to={`javascript:;`}>
                                                        <div className="text-wrap">
                                                            <strong className="tit line-2">{eduContent.qstCardVO.cardName}</strong>
                                                            <span className="job">{eduContent.eduMainVO.eduTitle}</span>
                                                        </div>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            <div className="text-area" key={renderKey}>
                                <SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        }</>
    );
}

export default useStoryDetail