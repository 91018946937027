import React, {useEffect, useState} from "react";
import {EduContentVO, EduMainVO} from "../../../../core/education/education.vo";

interface UseStoryEduSelectProps {
    contentList: EduContentVO[],
    selectEduContent: EduContentVO | null,
    setSelectEduContent:(eduContent: EduContentVO) => void;
}

const UseStorySelectEdu: React.FC<UseStoryEduSelectProps> = ({contentList, selectEduContent, setSelectEduContent}) => {

    useEffect(() => {
        
        console.log("contentList : ", contentList)

    }, [contentList]);


    return (
        <>
            { contentList.length == 0 && <div className="content-empty">진행한 코스학습 콘텐츠가 없습니다.</div> }


            {contentList && contentList.length > 0 &&
                <ul className="option-list pro-list">
                    {contentList.map((item, index) => (
                        <li key={index} onClick={() => setSelectEduContent(item)}>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios2"
                                    id={`ecIdx-${item.ecIdx}`}
                                    className="inp-radio"
                                    checked={selectEduContent?.ecIdx === item.ecIdx}
                                />
                                <label htmlFor={`ecIdx-${item.ecIdx}`} className="lab-radio">
                                    { item.contentType === `mov` &&
                                        <>
                                            <div className="image-wrap">
                                                <img src={item.qstMovVO.movThumb} alt={item.qstMovVO.movTitle} className="img-thumb" />
                                            </div>
                                            <div className="text-wrap">
                                                <strong className="tit line-2">{item.qstMovVO.movTitle}</strong>
                                                <div className="info">
                                            <span>
                                                {item.eduMainVO.eduTitle}
                                            </span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    { item.contentType === `card` &&
                                        <>
                                            <div className="text-wrap">
                                                <strong className="tit line-2">{item.qstCardVO.cardName}</strong>
                                                <div className="info">
                                            <span>
                                                {item.eduMainVO.eduTitle}
                                            </span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            }

        </>
    )
}

export default UseStorySelectEdu;